import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from "use-query-params";

import { EP, callApi } from '../../api';
import TransitionAlert from '../../components/transition-alert';
import Loading from '../../components/loading';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import UserForm from '../../components/user-form';

const EditUserPage = ({path}) => {
  const auth = useAuth();
  const [username] = useQueryParam('username', StringParam);
  const { user, isLoading, isError } = useFetchUser(auth, username);
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Edit user" />
      { isLoading && <Loading /> }
      { isError && (
        <TransitionAlert severity="error" isOpen>
          There was an error fetching the user.
        </TransitionAlert>
      )}
      { user && (
        <UserForm action="edit" user={user} />
      )}
    </Layout>
  )
}

const reshapeUser = user => {
  const transformedAttrs = user.UserAttributes.reduce((acc, attr) => ({ ...acc, [attr.Name]: attr.Value }), {});
  return {
    givenName: transformedAttrs.given_name,
    name: transformedAttrs.name,
    email: transformedAttrs.email,
    username: user.Username,
    role: transformedAttrs['custom:role'],
    stores: transformedAttrs['custom:stores'],
    tenantId: transformedAttrs['custom:tenantid'],
  }
}


const useFetchUser = (auth, username) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const user = await callApi(EP.users.get, { username });
        setIsLoading(false);
        setUser(reshapeUser(user));
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, [auth]);
  
  return { user, isLoading, isError };
}

export default EditUserPage;
